<template>
  <v-container class="px-6">
    <v-row justify="center">
      <v-col md="6">
        <!-- [Brand] -->
        <div class="rv-brand | my-4">
          <svg
            id="Grupo_62"
            data-name="Grupo 62"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="229.5"
            height="36"
            viewBox="0 0 229.5 36"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="Rectángulo_53"
                  data-name="Rectángulo 53"
                  width="229.5"
                  height="36"
                  fill="none"
                />
              </clipPath>
            </defs>
            <g id="Grupo_61" data-name="Grupo 61" clip-path="url(#clip-path)">
              <path
                id="Trazado_54"
                data-name="Trazado 54"
                d="M35.037,30.378,22.779,18.093l12.2-12.2A3.454,3.454,0,0,0,32.547,0H3.445A3.449,3.449,0,0,0,0,3.454V32.54a3.445,3.445,0,0,0,5.878,2.446l12.25-12.243L30.392,35.035a3.289,3.289,0,1,0,4.646-4.656M6.6,6.619h18.3L6.6,24.913Z"
                fill="#2852ef"
              />
              <path
                id="Trazado_55"
                data-name="Trazado 55"
                d="M96.678,20.95a2.417,2.417,0,0,1,.2-.975,2.544,2.544,0,0,1,.514-.775,2.349,2.349,0,0,1,.746-.513,2.259,2.259,0,0,1,.917-.187H113.9a2.286,2.286,0,0,1,.954.2,2.388,2.388,0,0,1,.758.537,2.582,2.582,0,0,1,.5.775,2.4,2.4,0,0,1,.184.938v5.975a2.418,2.418,0,0,1-.2.975,2.538,2.538,0,0,1-.514.775,2.374,2.374,0,0,1-1.663.7h-2.5L116.54,36h-3.669l-5.21-6.625h-5.993V26.4h11.6V21.475H99.711V36H96.678Z"
                transform="translate(-48.339 -9.25)"
                fill="#fff"
              />
              <path
                id="Trazado_56"
                data-name="Trazado 56"
                d="M145.7,20.975a2.417,2.417,0,0,1,.2-.975,2.718,2.718,0,0,1,.514-.788,2.261,2.261,0,0,1,1.663-.712h16.023v2.975H148.732v4.1h13.38V28.55h-13.38v4.475h15.361V36H148.169a2.359,2.359,0,0,1-.966-.2,2.585,2.585,0,0,1-.783-.525,2.463,2.463,0,0,1-.527-.763,2.268,2.268,0,0,1-.2-.937Z"
                transform="translate(-72.849 -9.25)"
                fill="#fff"
              />
              <path
                id="Trazado_57"
                data-name="Trazado 57"
                d="M191.539,18.5h3.034V33.025h13.4V36h-14.04a2.28,2.28,0,0,1-.954-.2,2.476,2.476,0,0,1-.758-.525,2.419,2.419,0,0,1-.685-1.7Z"
                transform="translate(-95.77 -9.25)"
                fill="#fff"
              />
              <path
                id="Trazado_58"
                data-name="Trazado 58"
                d="M232,20.975a2.417,2.417,0,0,1,.2-.975,2.718,2.718,0,0,1,.514-.788,2.261,2.261,0,0,1,1.663-.712h16.023v2.975H235.031v4.1h13.381V28.55H235.031v4.475h15.362V36H234.469a2.359,2.359,0,0,1-.966-.2,2.585,2.585,0,0,1-.783-.525,2.463,2.463,0,0,1-.526-.763,2.268,2.268,0,0,1-.2-.937Z"
                transform="translate(-115.999 -9.25)"
                fill="#fff"
              />
              <path
                id="Trazado_59"
                data-name="Trazado 59"
                d="M276.615,18.5h3.254l4.648,14.525h3.938L293.1,18.5h3.2l-5.015,15.1a3.958,3.958,0,0,1-1.076,1.763,2.605,2.605,0,0,1-1.81.638h-3.841a2.62,2.62,0,0,1-1.859-.638A4.275,4.275,0,0,1,281.63,33.6Z"
                transform="translate(-138.307 -9.25)"
                fill="#fff"
              />
              <path
                id="Trazado_60"
                data-name="Trazado 60"
                d="M323.288,20.975a2.417,2.417,0,0,1,.2-.975,2.719,2.719,0,0,1,.514-.788,2.261,2.261,0,0,1,1.663-.712h16.022v2.975H326.321v4.1H339.7V28.55H326.321v4.475h15.362V36H325.758a2.359,2.359,0,0,1-.966-.2,2.584,2.584,0,0,1-.782-.525,2.463,2.463,0,0,1-.527-.763,2.268,2.268,0,0,1-.2-.937Z"
                transform="translate(-161.644 -9.25)"
                fill="#fff"
              />
              <path
                id="Trazado_61"
                data-name="Trazado 61"
                d="M369.374,21.65a8.833,8.833,0,0,1,.11-1.437,3.557,3.557,0,0,1,.379-1.15,2.1,2.1,0,0,1,.721-.775A2.039,2.039,0,0,1,371.7,18a2.569,2.569,0,0,1,1.492.438,8.158,8.158,0,0,1,1.345,1.212L386.2,32.15V18.25h3.034v14.1a8.6,8.6,0,0,1-.111,1.413,3.743,3.743,0,0,1-.379,1.162A1.955,1.955,0,0,1,386.888,36a2.43,2.43,0,0,1-1.492-.437,10.663,10.663,0,0,1-1.321-1.213L372.407,21.875V35.75h-3.033Z"
                transform="translate(-184.687 -9)"
                fill="#fff"
              />
              <path
                id="Trazado_62"
                data-name="Trazado 62"
                d="M426.564,21.475h-8.806V18.5h20.621v2.975H429.6V36h-3.033Z"
                transform="translate(-208.879 -9.25)"
                fill="#fff"
              />
            </g>
          </svg>
        </div>

        <p class="text-body-2 | font-italic | text--disabled">
          Ingresa correctamente tu
          <strong class="text--secondary">RUT</strong> y descarga tu
          <strong class="text--secondary">RelevendID</strong> en tu móvil y
          <strong class="text--secondary">agiliza</strong> tu ingreso.
        </p>

        <!-- [Content] -->
        <v-card class="mt-6">
          <v-card-text>
            <v-row>
              <!-- (form) -->
              <v-col>
                <v-text-field
                  placeholder="Rut"
                  color="#2852EF"
                  outlined
                  dense
                  clearable
                  hide-details
                  v-model="numberId"
                />
              </v-col>

              <!-- (button) -->
              <v-col cols="5">
                <v-btn
                  @click="generateQR"
                  :disabled="!isValid"
                  color="#2852EF"
                  large
                  block
                  :loading="loading"
                >
                  Crear ID
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!--  -->
        <div v-show="false" id="qrcode" ref="qrcode" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Rut from "rutjs";
import { jsPDF } from "jspdf";
import QRCode from "@keeex/qrcodejs-kx";
import { mapState } from "vuex";

const logo = require("../assets/relevent.png");

export default {
  data: () => ({
    urlId: "",
    isValid: false,
    numberId: "",
    loading: false,
  }),

  watch: {
    numberId(id) {
      const v = id ? id : " ";

      const value = v.replace(/\s/g, "");
      var rut = new Rut(String(value));

      this.isValid = rut.validate();
      this.numberId = rut.getNiceRut(false);
    },
  },

  computed: {
    ...mapState(["desktop"]),
  },

  methods: {
    async generateQR() {
      this.loading = true;

      new QRCode(
        document.getElementById("qrcode"),
        `https://relevent.cl/user-id/?RUN=${this.numberId}`
      );

      await this.sleep(3000);
      this.onDownload();

      this.loading = false;
    },

    onDownload() {
      const doc = new jsPDF("p", "mm", [150, 75]);
      const image = document.getElementById("qrcode").querySelector("img").src;

      // Background
      doc.rect(0, 0, 75, 150, "F");

      // Logo
      var imgLogo = new Image();
      imgLogo.src = logo;
      doc.addImage(imgLogo, "JPEG", 15, 10, 45, 7);

      // QR
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(10, 60, 55, 55, 1, 1, "F");
      doc.addImage(image, "JPEG", 15, 65, 45, 45);

      // Label
      doc.setTextColor(100);
      doc.setFontSize(8);
      doc.text("RUT ASISTENTE:", 37.5, 35, "center");

      // Rut
      doc.setTextColor("#FFFFFF");
      doc.setFontSize(24);
      doc.text(this.numberId, 37.5, 43, "center");

      doc.setTextColor(70);
      doc.setFontSize(7);
      doc.text(
        10,
        134,
        "ReleventID es personal e instransferible, su mal uso sera penalizado con el bloqueo de tu RUT. Valido solo para ingresos operados por nuestra plataforma",
        { maxWidth: 55 }
      );

      if (this.desktop) {
        doc.output("dataurlnewwindow", { filename: "ReleventID" });
      } else {
        doc.save("ReleventID.pdf");
      }

      this.$router.go(0);
    },

    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
  },

  beforeCreate() {
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", "#121212");
  },
};
</script>